import { Injectable } from '@angular/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import { append, patch, removeItem } from '@ngxs/store/operators';
import { DateTime } from 'luxon';
import { catchError, concatMap, mergeMap, Observable, tap } from 'rxjs';
import {
  LoadFailed,
  SaveFailed,
  SaveSucceed,
} from 'src/app/core/actions/app.action';
import { LoadAccountSettings } from 'src/app/core/actions/settings.action';
import { AccountSettingsService } from 'src/app/core/api/account/v2/account-settings.service';
import { DeliveriesV2Service } from 'src/app/core/api/deliveries/v2/deliveries-v2.service';
import { AccountSettingsState } from 'src/app/core/states/account-settings.state';
import { AccountState } from 'src/app/core/states/account.state';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { Pageable } from 'src/app/shared/components/data-table-v2/model/pageable.model';
import { AccountSettingsUpdate } from 'src/app/shared/models/account/account-settings.model';
import {
  DeliveriesBuyOrders,
  Delivery,
} from 'src/app/shared/models/delivery/v2/deliveries-v2.model';
import {
  AddFilterParam,
  AddSearchParam,
  CloseOrder,
  CloseOverdueOrderDialog,
  ColumnsSelected,
  HideColumn,
  InitializeOverdueOrdersState,
  LoadOverdueOrders,
  Paginate,
  RemoveAllFilters,
  RemoveSearchParam,
  ResetPagination,
  ResetPaginationAndLoadData,
  Sort,
  ToggleFilter,
  UpdateExpectedDeliveryDate,
} from '../actions/overdue-orders.actions';
import {
  columnsGroupsMap,
  defaultColumnsV2,
  defaultFiltersV2,
  defaultPagination,
  defaultSort,
  filtersGroupsMap,
} from '../model/data-table.model';
import {
  DatatableState,
  DatatableStateModel,
} from 'src/app/shared/components/design-system/data-table-v2/state/data-table.state';
import {
  DatatableColumnV2,
  DatatableColumnV2Groups,
  DatatablePagination,
  DatatableParam,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import {
  Sorted,
  SortedOrder,
} from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import { ServiceRequestInfoV3 } from 'src/app/shared/components/design-system/data-table-v2/model/pageable-v2.model';
import { FilterTypesOptionsV2 } from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter-v2.model';
import { WebshopSelected } from 'src/app/core/actions/webshop.action';
import {
  AddSharedFilterParam,
  AddSharedSearchParam,
  RemoveAllSharedFilters,
  RemoveSharedSearchParam,
  ToggleSharedFilter,
} from '../../../actions/deliveries-shared.actions';
import { Filter } from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter.model';

export interface OverdueOrdersStateModel extends DatatableStateModel {
  orders: Delivery[];
  page: Pageable;
  loading: boolean;
  orderBeingUpdated: string | null;
}

@State<OverdueOrdersStateModel>({
  name: 'overdueOrdersState',
  defaults: {
    pagination: defaultPagination,
    columnsGroups: defaultColumnsV2,
    filtersGroups: defaultFiltersV2,
    sortBy: defaultSort,
    search: null,
    filterOpened: false,
    orders: [],
    page: { totalElements: 0 },
    loading: false,
    orderBeingUpdated: null,
  },
})
@Injectable()
export class OverdueOrdersState extends DatatableState {
  static filterDataByColumnKey(columnKey: string) {
    return createSelector(
      [OverdueOrdersState],
      (state: OverdueOrdersStateModel) => {
        const groupKey = filtersGroupsMap.get(columnKey);

        if (!groupKey) {
          throw new Error(`Column group ${groupKey} does not exist`);
        }

        if (state.filtersGroups[groupKey].columns[columnKey] === undefined) {
          throw new Error(`Column ${columnKey} does not exist`);
        }

        const sortBy = new Map(Object.entries(state.sortBy));

        return {
          columnKey,
          filter: state.filtersGroups[groupKey].columns[columnKey],
          filtered:
            !!state.filtersGroups[groupKey].columns[columnKey].params.value,
          sorted: sortBy.has(
            state.filtersGroups[groupKey].columns[columnKey]?.key
          )
            ? (sortBy.get(state.filtersGroups[groupKey].columns[columnKey].key)
                .order as SortedOrder)
            : null,
        };
      }
    );
  }

  @Selector()
  static orders(state: OverdueOrdersStateModel): Delivery[] {
    return state.orders;
  }

  constructor(
    private store: Store,
    private deliveriesV2Service: DeliveriesV2Service,
    private accountSettingsService: AccountSettingsService
  ) {
    super();
  }

  @Action(InitializeOverdueOrdersState, { cancelUncompleted: true })
  initialize(ctx: StateContext<OverdueOrdersStateModel>) {
    const tableSettings = this.store.selectSnapshot(
      AccountSettingsState.overdueDeliveriesTableSettings
    );

    const columns = this.initializeColumnsFromPersistence(
      ctx.getState().columnsGroups,
      tableSettings.excludedColumns
    );

    ctx.setState(
      patch<OverdueOrdersStateModel>({
        columnsGroups: columns,
        pagination: patch<DatatablePagination>({
          size: tableSettings.pageSize,
        }),
      })
    );

    return ctx.dispatch(new LoadOverdueOrders());
  }

  @Action(LoadOverdueOrders, { cancelUncompleted: true })
  loadOverdueOrders(ctx: StateContext<OverdueOrdersStateModel>) {
    ctx.patchState({
      loading: true,
    });

    return this._fetchOverdueOrders(ctx).pipe(
      tap((orders: DeliveriesBuyOrders) => {
        ctx.patchState({
          loading: false,
          orders: orders.data,
          page: { totalElements: orders.metadata.page.totalElements },
        });
      })
    );
  }

  @Action(Paginate, { cancelUncompleted: true })
  paginate(ctx: StateContext<OverdueOrdersStateModel>, payload: Paginate) {
    const state = ctx.getState();

    const didPageSizeChange =
      ctx.getState().pagination.size !== payload.pagination.pageSize;

    if (didPageSizeChange) {
      return this._saveTableSizePersistance(
        ctx,
        payload.pagination.pageSize
      ).pipe(
        tap(() => {
          ctx.patchState({
            pagination: {
              ...state.pagination,
              page: payload.pagination.pageIndex,
              size: payload.pagination.pageSize,
            },
          });
        }),
        mergeMap(() => ctx.dispatch(new LoadOverdueOrders())),
        mergeMap(() =>
          ctx.dispatch(
            new LoadAccountSettings(
              this.store.selectSnapshot(AccountState.userUuid)
            )
          )
        )
      );
    }

    ctx.patchState({
      pagination: {
        ...state.pagination,
        page: payload.pagination.pageIndex,
        size: payload.pagination.pageSize,
      },
    });

    return ctx.dispatch(new LoadOverdueOrders());
  }

  @Action(UpdateExpectedDeliveryDate, { cancelUncompleted: true })
  updateExpectedDeliveryDate(
    ctx: StateContext<OverdueOrdersStateModel>,
    payload: UpdateExpectedDeliveryDate
  ) {
    ctx.patchState({
      orderBeingUpdated: payload.buyOrderUUID,
    });

    return this._updateExpectedDeliveryDate(ctx, payload).pipe(
      concatMap(() => ctx.dispatch(new SaveSucceed())),
      concatMap(() => ctx.dispatch(new LoadOverdueOrders())),
      tap(() => {
        ctx.patchState({
          orderBeingUpdated: null,
        });
      })
    );
  }

  @Action(CloseOrder, { cancelUncompleted: true })
  closeOrder(ctx: StateContext<OverdueOrdersStateModel>, payload: CloseOrder) {
    return this._saveCompleteDeliveries(ctx, payload.orderUUID).pipe(
      concatMap(() =>
        ctx.dispatch([new SaveSucceed(), new CloseOverdueOrderDialog()])
      ),
      concatMap(() => ctx.dispatch(new LoadOverdueOrders()))
    );
  }

  @Action([AddFilterParam, AddSharedFilterParam], { cancelUncompleted: true })
  addFilterParam(
    ctx: StateContext<OverdueOrdersStateModel>,
    payload: AddFilterParam
  ) {
    const groupKey = filtersGroupsMap.get(payload.param.columnKey);

    if (!groupKey) return;

    this.addGroupParam(ctx, groupKey, payload.param.columnKey, {
      operator: payload.param.optionSelected as FilterTypesOptionsV2,
      subOperator: payload.param.subOperator,
      value: payload.param.values,
    });

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action([AddSearchParam, AddSharedSearchParam], { cancelUncompleted: true })
  addSearchParam(
    ctx: StateContext<OverdueOrdersStateModel>,
    payload: AddSearchParam
  ) {
    ctx.setState(
      patch<OverdueOrdersStateModel>({
        search: append<string>([payload.param]),
      })
    );

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action([RemoveSearchParam, RemoveSharedSearchParam], {
    cancelUncompleted: true,
  })
  removeSearchParam(
    ctx: StateContext<OverdueOrdersStateModel>,
    payload: RemoveSearchParam
  ) {
    ctx.setState(
      patch<OverdueOrdersStateModel>({
        search: removeItem<string>(
          searchParam => searchParam === payload.param
        ),
      })
    );

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(Sort, { cancelUncompleted: true })
  sortTable(ctx: StateContext<OverdueOrdersStateModel>, payload: Sort) {
    return this.sort(ctx, payload.sort);
  }

  @Action(ColumnsSelected, { cancelUncompleted: true })
  columnsSelected(
    ctx: StateContext<OverdueOrdersStateModel>,
    payload: ColumnsSelected
  ) {
    return this._saveTableColumnsPersistance(
      ctx,
      payload.selection.columnsGroups
    ).pipe(
      tap(() => {
        ctx.setState(
          patch({
            columnsGroups: {
              ...ctx.getState().columnsGroups,
              ...payload.selection.columnsGroups,
            },
          })
        );
      }),
      mergeMap(() =>
        ctx.dispatch(
          new LoadAccountSettings(
            this.store.selectSnapshot(AccountState.userUuid)
          )
        )
      )
    );
  }

  @Action(HideColumn, { cancelUncompleted: true })
  hideColumn(ctx: StateContext<OverdueOrdersStateModel>, payload: HideColumn) {
    const groupKey = columnsGroupsMap.get(payload.columnKey);

    if (!groupKey) return;

    const newColumnSelection = this.hideGroupColumnFromColumnKey(
      ctx.getState().columnsGroups,
      groupKey,
      payload.columnKey
    );

    return this._saveTableColumnsPersistance(ctx, newColumnSelection).pipe(
      tap(() => {
        ctx.patchState({
          columnsGroups: newColumnSelection,
        });
      }),
      mergeMap(() =>
        ctx.dispatch(
          new LoadAccountSettings(
            this.store.selectSnapshot(AccountState.userUuid)
          )
        )
      )
    );
  }

  @Action([RemoveAllFilters, RemoveAllSharedFilters], {
    cancelUncompleted: true,
  })
  removeAllFilters(ctx: StateContext<OverdueOrdersStateModel>) {
    this._removeAllFilters(ctx);

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(WebshopSelected)
  removeAllFiltersOnWebshopChange(ctx: StateContext<OverdueOrdersStateModel>) {
    this._removeAllFilters(ctx);
  }

  @Action([ToggleFilter, ToggleSharedFilter], { cancelUncompleted: true })
  toggleFilter(ctx: StateContext<OverdueOrdersStateModel>) {
    ctx.patchState({
      filterOpened: !ctx.getState().filterOpened,
    });
  }

  @Action(ResetPagination)
  resetPagination(
    ctx: StateContext<OverdueOrdersStateModel>,
    payload: ResetPagination
  ) {
    ctx.patchState({
      pagination: {
        ...ctx.getState().pagination,
        page: payload.page,
      },
    });
  }

  @Action(ResetPaginationAndLoadData, { cancelUncompleted: true })
  resetPaginationAndLoadData(ctx: StateContext<OverdueOrdersStateModel>) {
    return ctx.dispatch([new ResetPagination(), new LoadOverdueOrders()]);
  }

  override addGroupParam(
    ctx: StateContext<OverdueOrdersStateModel>,
    groupKey: string,
    columnKey: string,
    param: DatatableParam
  ): void {
    ctx.setState(
      patch<OverdueOrdersStateModel>({
        filtersGroups: patch({
          [groupKey]: patch({
            columns: patch({
              [columnKey]: patch({
                params: patch<DatatableParam>({
                  ...param,
                  operator:
                    param.operator ??
                    defaultFiltersV2[groupKey].columns[columnKey].params
                      .operator,
                  subOperator:
                    param.subOperator ??
                    defaultFiltersV2[groupKey].columns[columnKey].params
                      .subOperator,
                }),
              }),
            }),
          }),
        }),
      })
    );
  }

  sort(ctx: StateContext<OverdueOrdersStateModel>, sort: Sorted): void {
    ctx.patchState({
      sortBy: {
        [sort.key]: {
          field: sort.key,
          order: sort.order,
        },
      },
    });

    ctx.dispatch(new LoadOverdueOrders());
  }

  private _removeAllFilters(ctx: StateContext<OverdueOrdersStateModel>) {
    ctx.patchState({
      filtersGroups: defaultFiltersV2,
      search: [],
      sortBy: defaultSort,
    });
  }

  private _fetchOverdueOrders(
    ctx: StateContext<OverdueOrdersStateModel>
  ): Observable<DeliveriesBuyOrders | void> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const requestInfo: ServiceRequestInfoV3 =
      this._buildFetchOpenBuyOrdersRequestInfo(ctx.getState());

    return this.deliveriesV2Service
      .fetchOpenBuyOrders(webshopUuid, requestInfo)
      .pipe(
        catchError(() => {
          ctx.patchState({
            loading: false,
            orders: [],
            page: { totalElements: 0 },
          });

          return ctx.dispatch(new LoadFailed());
        })
      );
  }

  private _buildFetchOpenBuyOrdersRequestInfo(
    state: OverdueOrdersStateModel
  ): ServiceRequestInfoV3 {
    const filtersWithoutGroups: Filter[] = Object.values(state.filtersGroups)
      .map(group => Object.values(group.columns))
      .flat();

    const requestInfo: ServiceRequestInfoV3 = {
      queryData: {
        filters: this.buildFiltersV2(filtersWithoutGroups),
        sort_by: this.buildSortBy(state.sortBy),
        page: {
          from: state.pagination.page,
          size: state.pagination.size,
        },
      },
    };

    if (state.search !== null && !!state.search?.length) {
      requestInfo.queryData.query = this.buildQuery(state.search);
    }

    return requestInfo;
  }

  private _updateExpectedDeliveryDate(
    ctx: StateContext<OverdueOrdersStateModel>,
    payload: UpdateExpectedDeliveryDate
  ): Observable<any> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    return this.deliveriesV2Service
      .updateExpectedDeliveryDate(webshopUuid, payload.buyOrderUUID, {
        expectedDeliveryDate: DateTime.fromJSDate(
          payload.deliveryDate
        ).toFormat('yyyy-MM-dd'),
      })
      .pipe(
        catchError(() => {
          return ctx.dispatch(new SaveFailed());
        })
      );
  }

  private _saveCompleteDeliveries(
    ctx: StateContext<OverdueOrdersStateModel>,
    orderUuid: string
  ): Observable<any> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    return this.deliveriesV2Service
      .saveAndCompleteOrder(webshopUuid, orderUuid)
      .pipe(
        catchError(() => {
          return ctx.dispatch([
            new CloseOverdueOrderDialog(),
            new SaveFailed(),
          ]);
        })
      );
  }

  private _saveTableColumnsPersistance(
    ctx: StateContext<OverdueOrdersStateModel>,
    columnsGroups: DatatableColumnV2Groups<DatatableColumnV2>
  ): Observable<any> {
    const requestInfo: AccountSettingsUpdate =
      this._buildSaveColumnPersistanceRequestInfo(columnsGroups);

    return this._updateAccountSettings(ctx, requestInfo);
  }

  private _buildSaveColumnPersistanceRequestInfo(
    columnsGroups: DatatableColumnV2Groups<DatatableColumnV2>
  ): AccountSettingsUpdate {
    const excludedColumns: string[] = this.buildExcludedColumns(columnsGroups);

    return {
      overdueDeliveriesTableExcludedColumns: excludedColumns,
      overrideOverdueDeliveriesTableExcludedColumns: true,
    };
  }

  private _saveTableSizePersistance(
    ctx: StateContext<OverdueOrdersStateModel>,
    newPageSize: number
  ): Observable<any> {
    const requestInfo: AccountSettingsUpdate =
      this._buildSaveTableSizePersistanceRequestInfo(newPageSize);

    return this._updateAccountSettings(ctx, requestInfo);
  }

  private _buildSaveTableSizePersistanceRequestInfo(
    newPageSize: number
  ): AccountSettingsUpdate {
    return {
      overdueDeliveriesTablePageSize: newPageSize,
    };
  }

  private _updateAccountSettings(
    ctx: StateContext<OverdueOrdersStateModel>,
    requestInfo: AccountSettingsUpdate
  ): Observable<any> {
    const userUuid = this.store.selectSnapshot(AccountState.userUuid);

    return this.accountSettingsService
      .updateSettings(userUuid, requestInfo)
      .pipe(
        catchError(e => {
          ctx.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }
}
