import { ColumnSelectionV3 } from 'src/app/shared/components/design-system/data-table-v2/components/column-view-v3/model/column-view-v3.model';
import { PageEventV2 } from 'src/app/shared/components/design-system/data-table-v2/components/paginator/paginator.component';
import { Sorted } from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import { ColumnFilterParam } from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';

export class InitializeExpectedOrdersState {
  static readonly type = '[Deliveries Expected Orders] Initialize';
}

export class LoadExpectedOrders {
  static readonly type = '[Deliveries Expected Orders] Load';
}

export class Paginate {
  static readonly type = '[Deliveries Expected Orders] Paginate';

  constructor(public pagination: PageEventV2) {}
}

export class AddSearchParam {
  static readonly type = '[Deliveries Expected Orders] Add Search Param';

  constructor(public param: string | null) {}
}

export class RemoveSearchParam {
  static readonly type = '[Deliveries Expected Orders] Remove Search Param';

  constructor(public param: string | null) {}
}

export class AddFilterParam {
  static readonly type = '[Deliveries Expected Orders] Add Filter Param';

  constructor(public param: ColumnFilterParam) {}
}

export class Sort {
  static readonly type = '[Deliveries Expected Orders] Sort';

  constructor(public sort: Sorted) {}
}

export class RemoveFilterParam {
  static readonly type = '[Deliveries Expected Orders] Remove Filter Param';
}

export class RemoveAllFilters {
  static readonly type = '[Deliveries Expected Orders] Remove All Filters';
}

export class ResetPagination {
  static readonly type = '[Deliveries Expected Orders] Reset Pagination';

  constructor(public page: number = 0) {}
}

export class ColumnsSelected {
  static readonly type = '[Deliveries Expected Orders] Columns Selected';

  constructor(public selection: ColumnSelectionV3) {}
}

export class HideColumn {
  static readonly type = '[Deliveries Expected Orders] Hide Column';

  constructor(public columnKey: string) {}
}

export class ToggleFilter {
  static readonly type = '[Deliveries Expected Orders] Toggle Filter';
}

export class UpdateExpectedDeliveryDate {
  static readonly type =
    '[Deliveries Expected Orders] Update Expected Delivery Date';

  constructor(
    public buyOrderUUID: string,
    public deliveryDate: Date
  ) {}
}

export class CloseOrder {
  static readonly type = '[Deliveries Expected Orders] Close Expected Order';

  constructor(public orderUUID: string) {}
}

export class CloseExpectedOrderDialog {
  static readonly type = '[Deliveries Orders] Close Expected Order Dialog';
}

export class ResetPaginationAndLoadData {
  static readonly type =
    '[Deliveries Expected Orders] Reset Pagination And Load Data';
}

export class AddFilterParamNoReload {
  static readonly type =
    '[Deliveries Expected Orders] Add Filter Param No Reload';

  constructor(
    public param: ColumnFilterParam[],
    public reload?: boolean
  ) {}
}
